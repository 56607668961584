(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/news-tt/assets/news-tt-hooks.js') >= 0) return;  svs.modules.push('/components/sportinfo/news-tt/assets/news-tt-hooks.js');
"use strict";


const {
  useDispatch,
  useSelector,
  shallowEqual
} = ReactRedux;
const {
  useEffect
} = React;
const {
  fetchNews
} = svs.components.sportinfo.newsTT.actions;
const FIVE_MINUTES_AS_MILLISECONDS = 1000 * 60 * 5;
const useFetchNews = matchId => {
  const dispatch = useDispatch();
  const lastFetch = useSelector(state => {
    var _state$newsTT$lastFet;
    return (_state$newsTT$lastFet = state.newsTT.lastFetch[matchId]) !== null && _state$newsTT$lastFet !== void 0 ? _state$newsTT$lastFet : 0;
  });
  useEffect(() => {
    if (matchId) {
      const diff = Date.now() - lastFetch;
      if (diff > FIVE_MINUTES_AS_MILLISECONDS) {
        dispatch(fetchNews(matchId));
      }
    }
  }, [dispatch, matchId, lastFetch]);
};
const useNews = matchId => {
  var _useSelector;
  const newsIds = (_useSelector = useSelector(state => state.newsTT.byMatch[matchId])) !== null && _useSelector !== void 0 ? _useSelector : [];
  const matchArticles = useSelector(state => {
    const news = [];
    for (const newsId of newsIds) {
      news.push(state.newsTT.news[newsId]);
    }
    return news;
  }, shallowEqual);
  return matchArticles;
};
setGlobal('svs.components.sportinfo.newsTT.hooks', {
  useFetchNews,
  useNews
});

 })(window);