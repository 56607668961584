(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/news-tt/assets/news-tt-reducer.js') >= 0) return;  svs.modules.push('/components/sportinfo/news-tt/assets/news-tt-reducer.js');
"use strict";


const {
  createReducer
} = RTK;
const {
  fetchNews
} = svs.components.sportinfo.newsTT.actions;
const {
  ReducerState
} = svs.components.sport.tipsenShared;
const initialState = {
  state: ReducerState.Init,
  news: {},
  byMatch: {},
  lastFetch: {}
};
const newsTTReducer = createReducer(initialState, builder => {
  builder.addCase(fetchNews.pending, (state, action) => {
    var _action$meta$enhanche;
    state.state = ReducerState.Pending;
    state.lastFetch[action.meta.requestId] = (_action$meta$enhanche = action.meta.enhanchedData) === null || _action$meta$enhanche === void 0 ? void 0 : _action$meta$enhanche._svsTimestamp;
  }).addCase(fetchNews.rejected, (state, action) => {
    state.state = ReducerState.Rejected;
    delete state.lastFetch[action.meta.requestId];
  }).addCase(fetchNews.fulfilled, (state, action) => {
    const timestamp = state.lastFetch[action.meta.requestId];
    delete state.lastFetch[action.meta.requestId];
    const matchId = action.meta.arg;
    state.lastFetch[matchId] = timestamp;
    state.state = ReducerState.Done;
    state.byMatch[matchId] = [];
    for (const news of action.payload) {
      state.news[news.id] = news;
      state.byMatch[matchId].push(news.id);
    }
  });
});
setGlobal('svs.components.sportinfo.newsTT.newsTTReducer', newsTTReducer);

 })(window);