(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/news-tt/assets/news-tt-actions.js') >= 0) return;  svs.modules.push('/components/sportinfo/news-tt/assets/news-tt-actions.js');
"use strict";


const {
  createAsyncThunk
} = RTK;
const {
  ContentUrlBuilder
} = svs.content.shared;
const {
  jupiter
} = svs.core;
const fetchNewsFromJupiter = matchId => {
  const path = new ContentUrlBuilder('basicfilter', 'partner').addParams({
    parentId: 'svs-workarea-articles-news',
    domainCategories: "svs-domain-sport-events/".concat(matchId),
    sort: 'published:desc',
    count: 100
  }).build();
  return new Promise((resolve, reject) => {
    jupiter.get(path, response => {
      resolve(response.result);
    }, error => {
      reject(error);
    });
  });
};
const fetchNews = createAsyncThunk('news-tt/fetchNews', async matchId => fetchNewsFromJupiter(matchId));
setGlobal('svs.components.sportinfo.newsTT.actions', {
  fetchNews
});

 })(window);